import { useState } from "react";
import Snowflake from "./snowflake"; // Adjust the import path as necessary

const useSnowflakes = () => {
  const [snowflakes, setSnowflakes] = useState<JSX.Element[]>([]);

  const createSnowflake = (e: React.MouseEvent) => {
    const card = e.currentTarget as HTMLElement;
    const x = e.clientX;
    const y = e.clientY - card.getBoundingClientRect().top;

    const duration = Math.random() * 2 + 2; // Random duration

    const newSnowflake = (
      <Snowflake
        key={Date.now() + Math.random()} // Unique key
        left={x}
        top={y}
        duration={duration}
        onAnimationEnd={() => {
          setSnowflakes((prev) => prev.filter((_, index) => index !== 0));
        }}
      />
    );

    setSnowflakes((prev) => [...prev, newSnowflake]);
  };

  return { snowflakes, createSnowflake };
};

export default useSnowflakes;
