import ButtonToggleIcon from "../button/button-toggle-icon";

const HighlightCardContent = ({
  title,
  description,
  icon,
  actionLabel,
}: any) => {
  return (
    <div className="content absolute left-0 right-0 top-0 z-[1] flex h-full flex-col justify-between px-8 py-6">
      <div className="flex flex-col gap-2">
        <div className="bold text-lg font-semibold uppercase">{title}</div>
        <div className="text-xs">{description}</div>
      </div>
      <div className="flex">
        <ButtonToggleIcon label={actionLabel} icon={icon} />
      </div>
    </div>
  );
};

export default HighlightCardContent;
