"use client";
import Link from "antd/es/typography/Link";
import HighlightCardContent from "./highlight-card-content";
import useSnowflakes from "../../events/decorate/falldown-snowflake";

interface IProps {
  url: string;
  title: string;
  description: string;
  icon: any;
  actionIcon: any;
  actionLabel: string;
}

const HighlightCard = ({
  url,
  title,
  description,
  icon,
  actionIcon,
  actionLabel,
}: IProps) => {
  const { snowflakes, createSnowflake } = useSnowflakes(); // Use the custom hook

  return (
    <Link href={url} className="flex-1" onMouseMove={createSnowflake}>
      <div>
        <div className="relative h-[172px] overflow-hidden rounded-2xl border bg-gray-900/30 font-medium text-slate-700">
          <div
            className="absolute h-full w-full"
            style={{
              background:
                "linear-gradient(to bottom right,rgba(171,215,255,.3),rgba(72,133,224,.3))",
            }}
          ></div>
          <div className="absolute right-0 top-0">{icon}</div>

          <HighlightCardContent
            title={title}
            description={description}
            icon={actionIcon}
            actionLabel={actionLabel}
          />
        </div>
        {snowflakes}
      </div>
    </Link>
  );
};

export default HighlightCard;
