"use client";
import Image from "next/image";
import { useState, type ComponentProps } from "react";
export const runtime = "edge";
export default function BlurImage(
  props: ComponentProps<typeof Image> & { zoomLoading?: boolean },
) {
  const { zoomLoading = true } = props;
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Image
      className={`
        relative h-auto w-full object-cover duration-700 ease-in-out
        ${
          isLoading && zoomLoading
            ? "scale-110 blur-2xl grayscale"
            : "scale-100 blur-0 grayscale-0"
        }
      `}
      onLoadingComplete={() => setIsLoading(false)}
      {...props}
      loading={props.priority ? "eager" : "lazy"}
      alt={props.alt}
      fill={props.height || props.width ? undefined : true}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      quality={75}
    />
  );
}
